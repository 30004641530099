import Styles from "./Services.module.scss"
import { Container, Row, Col } from "react-bootstrap"
import cleaning1 from "../../assets/images/cleaning-1.jpg"
import cleaning2 from "../../assets/images/steam-cleaning-1000px.jpg"
import cleaning3 from "../../assets/images/desk-cleaning-1000px.jpg"
import cleaning4 from "../../assets/images/cleaning-4.jpg"
import { useTitle } from "../../Hooks/useTitle"

const Home = (props) => {

   useTitle(props.Title);

   return (
     <div className={Styles.services}>
        <Container className={Styles.container}>
           <Row className="form-group">
              <Col sm={6}>
                 <h2>Office Cleaning</h2>
                 <p>We will work closely with you to tailor a cleaning program that works for your facility. We also supply a diverse range of cleaning products and materials. Our services cover:</p>
                 <ul>
                    <li>Commercial offices</li>
                    <li>Corporate offices</li>
                    <li>Commercial office buildings</li>
                    <li>Gym facilities</li>
                    <li>Healthcare and medical facilities</li>
                    <li>Educational facilities</li>
                    <li>Once off clean</li>
                    <li>Cleaning products, material and bathroom amenities supply</li>
                 </ul>
              </Col>
              <Col sm={6} className="d-sm-block">
                 <img className={Styles.imgCleaning1} src={cleaning1} alt="cleaning"></img>
              </Col>
           </Row>
           <Row className="form-group">
              <Col sm={6}>
                 <h2>Carpet Cleaning</h2>
                 <p>Experts in carpet cleaning with over 30 years industry experience, our cleaning process ensures a safe, healthy and clean environment, using specialised environmentally friendly products. Services cover:</p>
                 <ul>
                    <li>Commercial and domestic carpets</li>
                    <li>Lounges</li>
                    <li>Mattresses</li>
                    <li>Rugs</li>
                    <li>Upholstery</li>
                    <li>Odour elimination </li>
                    <li>Carpet sanitisation & deodorising</li>
                 </ul>
              </Col>
              <Col sm={6} className="d-sm-block">
                 <img className={Styles.imgCleaning2} src={cleaning2} alt="cleaning"></img>
              </Col>
           </Row>
           <Row className="form-group">
              <Col sm={6}>
                 <h2>Additional Services</h2>
                 <ul>
                    <li>Once off clean</li>
                    <li>Stripping and resealing of vinyl floors</li>
                    <li>Window cleaning</li>
                    <li>High pressure wash</li>
                    <li>Anti bacterial cleaning</li>
                    <li>Hygiene services</li>
                    <li>Waste removal </li>
                 </ul>
              </Col>
              <Col sm={6} className="d-sm-block">
                 <img className={Styles.imgCleaning3} src={cleaning3} alt="cleaning"></img>
              </Col>
           </Row>
           <Row className="form-group">
              <Col sm={6}>
                 <h2>Products</h2>
                 <p>We stock and can supply a diverse range of cleaning products and materials including but not limited to:</p>
                 <ul>
                    <li>Hand paper towels</li>
                    <li>Liquid soap</li>
                    <li>Garbage bags (range of sizes)</li>
                    <li>Toilet paper</li>
                    <li>Hand sanitisers</li>
                    <li>Face masks</li>
                    <li>Gloves</li>
                 </ul>
              </Col>
              <Col sm={6} className="d-sm-block">
                 <img className={Styles.imgCleaning4} src={cleaning4} alt="cleaning"></img>
              </Col>
           </Row>
        </Container>
     </div>
  )
}

export default Home;