import Div100vh from 'react-div-100vh'
import { BrowserRouter, Switch, Route } from "react-router-dom";

//import Container, { fluid } from "react-bootstrap/Container";
// import Button from "react-bootstrap/Button";
// import ButtonToolbar from "react-bootstrap/ButtonToolbar";
// import { IndexLinkContainer } from "react-router-bootstrap";
// import Row from "react-bootstrap/Row";
// import Col from 'react-bootstrap/Col'
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons' 
import { faInstagram } from '@fortawesome/free-brands-svg-icons' 

import Logo from './assets/images/a-and-d-logo-1200px.png';
import './App.scss';

import Home from './Components/Home/Home.component';
import Services from './Components/Services/Services.component';
import About from './Components/About/About.component';
import ContactUs from './Components/ContactUs/ContactUs.component';

const App = () => (
   <BrowserRouter>
         <Container fluid className="p-0">
            <Div100vh className="App">
               <header className="App-header">
                  {/* <Container>
                  <Row className="align-items-center no-wrap">
                     <Col xs={"auto"}>
                        <img src={logo} className="App-logo" alt="logo" />
                     </Col>
                     <Col className="text-center">
                        <span>A and D Cleaning Services</span>
                     </Col>
                     <Col xs={"auto"} className="d-none d-lg-block">
                        <ButtonToolbar className="header-btn-toolbar">
                           <IndexLinkContainer to="/">
                              <div className="btn btn-outline-primary">Home</div>
                           </IndexLinkContainer>
                           <IndexLinkContainer to="/users">
                              <Button>Services</Button>
                           </IndexLinkContainer>
                           <IndexLinkContainer to="/about">
                              <Button>About</Button>
                           </IndexLinkContainer>
                        </ButtonToolbar>
                     </Col>
                  </Row>
               </Container> */}
                  <Container>
                     <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                        <Navbar.Brand href="/">
                           <img src={Logo} className="App-logo" alt="logo" />
                        </Navbar.Brand>
                        {/* <h1 className="d-inline-block">A & D Cleaning Services</h1> */}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                           {/* <Nav className="mr-auto">
                        <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                           <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                           <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                           <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                           <NavDropdown.Divider />
                           <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown>
                     </Nav> */}
                           <Nav className="ml-auto">
                              <Nav.Link href="/about">About Us</Nav.Link>
                              <Nav.Link href="/services">Our Services</Nav.Link>
                              <Button variant="primary ml-4" href="/contact">
                                 Contact Us
                              </Button>
                           </Nav>
                        </Navbar.Collapse>
                     </Navbar>
                  </Container>
               </header>
               <section className="content scroll">
                  <Switch>
                     <Route path="/contact">
                        <ContactUs Title="Contact Us"/>
                     </Route>
                     <Route path="/about">
                        <About Title="About Us"/>
                     </Route>
                     <Route path="/services">
                        <Services Title="Our Services" />
                     </Route>
                     <Route exact path="/">
                        <Home />
                     </Route>
                  </Switch>
               </section>
               <footer className="text-center p-2">
                  <a href="https://www.facebook.com/profile.php?id=100057498998018" alt="facebook" target="_blank" rel="noreferrer" title="facebook">
                     <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a className="mx-2" href="https://www.instagram.com/adcleaningau/?hl=bg" alt="instagram" target="_blank" rel="noreferrer" title="instagram">
                     <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <span className="ml-4">
                     Call <a className="tel" href="tel:0416 164 454">0416 164 454</a> for a quote or booking
                  </span>
               </footer>
            </Div100vh>
         </Container>
   </BrowserRouter>
)

export default App;
