import { useState, useEffect, useRef } from "react"
import Container from "react-bootstrap/Container"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import useInputValidator, { isNotEmpty, isEmail } from "../../Hooks/useInputValidator"

import { functions, analytics } from "../../Helpers/firebase"
import ReCAPTCHA from "react-google-recaptcha"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons' 
import { faInstagram } from '@fortawesome/free-brands-svg-icons' 

import styles from './ContactUs.module.scss'
import { useTitle } from "../../Hooks/useTitle"

function ContactUs(props) {

  const { value: firstName, setValue: firstNameSet, hasError: firstNameHasError, errorClass: firstNameErrorClass, valueChangedHandler: firstNameChangedHandler, inputBlurHandler: firstNameBlurHandler, reset: firstNameReset } = useInputValidator(isNotEmpty);
  const { value: lastName, setValue: lastNameSet, hasError: lastNameHasError, errorClass: lastNameErrorClass, valueChangedHandler: lastNameChangedHandler, inputBlurHandler: lastNameBlurHandler, reset: lastNameReset } = useInputValidator(isNotEmpty);
  const { value: email, setValue: emailSet, hasError: emailHasError, errorClass: emailErrorClass, valueChangedHandler: emailChangedHandler, inputBlurHandler: emailBlurHandler, reset: emailReset } = useInputValidator(isEmail);
  const { value: phone, hasError: phoneHasError, errorClass: phoneErrorClass, valueChangedHandler: phoneChangedHandler, inputBlurHandler: phoneBlurHandler, reset: phoneReset } = useInputValidator(isNotEmpty);
  const { value: comment, hasError: commentHasError, errorClass: commentErrorClass, valueChangedHandler: commentChangedHandler, inputBlurHandler: commentBlurHandler, reset: commentReset } = useInputValidator(isNotEmpty);
  const [formIsValid, formIsValidSet] = useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = useState(false);
  const [formIsSubmitted, formIsSubmittedSet] = useState(false);
  const [formAPIError, formAPIErrorSet] = useState();

  const [recaptcha, recaptchaSet] = useState();
  const recaptchaRef = useRef();

  useTitle(props.Title)

//   useEffect(() => {
//    //  firstNameReset();
//    //  lastNameReset();
//    //  emailReset();
//    //  phoneReset();
//    //  commentReset();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

  useEffect(() => {
    formIsValidSet(!firstNameHasError && !lastNameHasError && !emailHasError && !phoneHasError && !commentHasError && recaptcha);
  }, [commentHasError, emailHasError, firstNameHasError, lastNameHasError, phoneHasError, recaptcha]);

  const formSubmissionHandler = (event) => {
    event.preventDefault();
    formAPIErrorSet(null);
    if (!formIsValid) {
      return;
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      return;
    }

    analytics.logEvent('contact_us_submitting');
    
    formIsSubmittingSet(true);
    
    postContact({ firstName, lastName, email, phone, comment, recaptcha }).then(() => {
        formIsSubmittingSet(false);
        formIsSubmittedSet(true);
    }, (err) => {
       formIsSubmittingSet(false);
       formIsSubmittedSet(false);
       formAPIErrorSet(err);
      });
   };
   
   async function postContact(contact) {
      contact = {...contact, DateSubmitted: new Date().toLocaleString("en-AU", { timeZone: "Australia/Sydney" })}
      const contactUsFunction = functions.httpsCallable('contactUs');
      contactUsFunction(contact)
      .then(r => console.log(r.data))
      .catch(err => {
         console.log(err);
         analytics.logEvent('contact_us_submit_api_error');
         throw new Error("An error occured while submitting form");
      })
    // const response = await fetch('https://us-central1-adcleaning-staging.cloudfunctions.net/contactUs', {
    //   method: 'POST',
    //   body: JSON.stringify(contact),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // });
    // const data = await response.json();
    // console.log(data);
  }

  return (
     <Container className={styles.contact}>
        <Row>
           <Col xs={4} lg={3}>
              <label className="col-form-label">Phone:</label>
           </Col>
           <Col xs={8} lg={9}>
              <label className="col-form-label">
                 <a href="tel:0416 164 454">0416 164 454</a>
              </label>
           </Col>
        </Row>
        <Row>
           <Col xs={4} lg={3}>
              <label className="col-form-label">Email:</label>
           </Col>
           <Col xs={8} lg={9}>
              <label className="col-form-label">
                 <a href="mailto:info@adcleaning.com.au">info@adcleaning.com.au</a>
              </label>
           </Col>
        </Row>
        <Row>
           <Col xs={4} lg={3}>
              <label className="col-form-label">Postal Address:</label>
           </Col>
           <Col xs={8} lg={9}>
              <label className="col-form-label">
                 PO Box 276
                 <br />
                 Condell Park NSW 2200
              </label>
           </Col>
        </Row>
        <Row>
           <Col xs={4} lg={3}>
              <label className="col-form-label">Social Media:</label>
           </Col>
           <Col xs={8} lg={9} className={`${styles.social} mt-1`}>
              <a href="https://www.facebook.com/profile.php?id=100057498998018" alt="facebook" target="_blank" rel="noreferrer" title="facebook">
                 <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a className="ml-3" href="https://www.instagram.com/adcleaningau/?hl=bg" alt="instagram" target="_blank" rel="noreferrer" title="instagram">
                 <FontAwesomeIcon icon={faInstagram} />
              </a>
           </Col>
        </Row>
        <form noValidate>
           {!formIsSubmitted && (
              <>
                 <div className="my-3">
                    <b>Have any questions? Get in touch with us and let us know how we can help</b>
                 </div>
                 <Row className="mb-4">
                    <Col sm={4} lg={3}>
                       <label className="col-form-label text-nowrap">First Name:</label>
                    </Col>
                    <Col sm={8} lg={9}>
                       <input className={`form-control ${firstNameErrorClass}`} value={firstName} onBlur={firstNameBlurHandler} onChange={firstNameChangedHandler}></input>
                       <div className="invalid-feedback position-absolute">First Name must not be empty.</div>
                    </Col>
                 </Row>
                 <Row className="mb-4">
                    <Col sm={4} lg={3}>
                       <label className="col-form-label text-nowrap">Last Name:</label>
                    </Col>
                    <Col sm={8} lg={9}>
                       <input className={`form-control ${lastNameErrorClass}`} value={lastName} onBlur={lastNameBlurHandler} onChange={lastNameChangedHandler}></input>
                       <div className="invalid-feedback position-absolute">Last Name must not be empty.</div>
                    </Col>
                 </Row>
                 <Row className="mb-4">
                    <Col sm={4} lg={3}>
                       <label className="col-form-label">Email:</label>
                    </Col>
                    <Col sm={8} lg={9}>
                       <input className={`form-control ${emailErrorClass}`} value={email} onBlur={emailBlurHandler} onChange={emailChangedHandler}></input>
                       <div className="invalid-feedback position-absolute">Email must not be empty or invalid.</div>
                    </Col>
                 </Row>
                 <Row className="mb-4">
                    <Col sm={4} lg={3}>
                       <label className="col-form-label">Phone:</label>
                    </Col>
                    <Col sm={8} lg={9}>
                       <input className={`form-control ${phoneErrorClass}`} value={phone} onBlur={phoneBlurHandler} onChange={phoneChangedHandler}></input>
                       <div className={"invalid-feedback position-absolute"}>Phone must not be empty.</div>
                    </Col>
                 </Row>
                 <Row className="mb-4">
                    <div className="col">
                       <textarea className={`form-control ${commentErrorClass}`} value={comment} onBlur={commentBlurHandler} onChange={commentChangedHandler} placeholder="How can we help?" rows="5"></textarea>
                       <div className="invalid-feedback position-absolute">Request or Comment must not be empty.</div>
                    </div>
                 </Row>
              </>
           )}
           <Row className="mb-3 mb-lg-0 justify-content-between">
              <div className="col">
                 {formIsSubmitting && <div className="alert alert-info">Your request or comment is being submitted. Please wait...</div>}
                 {formIsSubmitted && !formAPIError && (
                    <>
                       <br />
                       <div className="alert alert-success d-inline-block mt-2">Your request or comment has been submitted.</div>
                       <br />
                       <h2>Thank you</h2>
                       <p>Thank you for your enquiry.</p>
                       <p>We will endeavour to get back to you within 48 hours and appreciate you taking the time to get in touch.</p>
                       <p>
                          For anything urgent, please contact us on <a href="tel:0416 164 454">0416 164 454</a>.
                       </p>
                    </>
                 )}
                 {formAPIError && (
                    <div className="alert alert-danger">
                       An error occurred while submitted your request or comment.
                       <br />
                       {formAPIError}
                    </div>
                 )}
              </div>
              {!formIsSubmitting && !formIsSubmitted && (
                 <>
                    <Col xl="auto">
                       <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={(value) => recaptchaSet(value)} />
                    </Col>
                    <Col xl="auto">
                       <button className="btn btn-primary" disabled={!formIsValid} onClick={(evt) => formSubmissionHandler(evt)}>
                          Submit
                       </button>
                    </Col>
                 </>
              )}
           </Row>
        </form>
     </Container>
  )
};

export default ContactUs;
