import { Container, Row, Col } from "react-bootstrap"
import image1 from "../../assets/images/ad-van-1000px.jpg"
import styles from "./About.module.scss"
import { useTitle } from "../../Hooks/useTitle"

function About(props) {

   useTitle(props.Title)

  return (
     <Container className={styles.about}>
        <Row className="form-group">
           <Col md={6}>
              <h2>Serving the Sydney community for over 30 years</h2>
              <p className="mt-3">
                 A&D Cleaning Services and Carpet Cleaning is a proud family run and ACCI accredited business  which has been serving the Sydney community for over 30 years. Established in 1987, A&D specialises in Commercial and Carpet Cleaning and offers a range of <a href="/services">Products and Services</a>.
              </p>
              <p>At the heart of A&D you will find co-founders Aida and Daniel Lattouf, with a dedicated team of highly trained cleaning professionals, servicing a diverse leading commercial and private client base, with longstanding relationships, from all over Sydney.</p>
              <p>
                 Our core values and commitment to you:
              </p>
               <ul>
                  <li>Ensuring a safe, healthy and clean environment</li>
                  <li>Delivering a quality and reliable service</li>
                  <li>Professional and friendly service</li>
               </ul>
              <p>
                 Have any other questions?
              </p>
              <p>
              <a href="/Contact">Get in touch</a> and let us know how we can help you.
              </p>
           </Col>
           <Col md={6}>
              <img className={styles.img1} src={image1} alt="ad van"/>
           </Col>
        </Row>
     </Container>
  )
};

export default About;
