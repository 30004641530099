import { useEffect } from "react"
import { analytics } from "../../Helpers/firebase"
import Styles from "./Home.module.scss"
import { Container, Button } from "react-bootstrap"
import CleanImg1 from "../../assets/images/office-1920px.jpg"

const Home = () => {

   useEffect(() => {
      analytics.logEvent("home_view")
   }, [])

  return (
     //  <div className={Styles.home} style={{ background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${background})`, backgroundRepeat: "repeat", backgroundSize: "100%"}}>
     <div className={Styles.home}>
        <div className={Styles.img1container}>
           <img className={Styles.img1} src={CleanImg1} alt="cleaning" />
           <div className={Styles.img1content}>
              <h2>Specialising in Commercial & Industrial Cleaning</h2>
              <Button variant="outline-primary mt-3" href="/services">
                 Our Services
              </Button>
           </div>
        </div>
        <Container className={Styles.container}>
           <div className="mt-4">
              <h2>Serving the Sydney community for over 30 years</h2>
              <p>Ensuring a safe, healthy and clean environment is at the heart of what we do. Specialising in Commercial and Industrial Cleaning, among other Services, A&D’s core values and commitment to you is delivering a professional, quality and reliable service.</p>
           </div>
        </Container>
     </div>
  )
  };

export default Home;